import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import PostList from "../../components/post_list/post_list"

class BlogIndex extends React.Component {
  render() {
    const {
      posts,
    } = this.props.pageContext
    const lang = posts[0].algorithm.frontmatter.lang
    const general_strings = require("./../../../content/" + lang + "/strings.json")
    const implementation_strings = require("./../../../content/" + lang + "/implementations/strings.json")
    const description = posts.map(x => x.algorithm.frontmatter.name + " in " + x.language.frontmatter.name).join(", ")

    return (
      <Layout lang={posts[0].algorithm.frontmatter.lang}>
        <SEO
          title={"All algorithms in all languages"}
          description={description}
          lang={"en"}
        />

        <article>
          <header>
            <h1>
              {general_strings.recent_posts}
            </h1>
          </header>

          <section className="row">
            <div className="col-lg-12">
              {posts ? <PostList posts={posts}/> : `No recent posts.`}
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default BlogIndex
